import { Reducer, useReducer } from "react";
import { FeedState, FeedStateActions } from "./interfaces";

const initialFeedState: FeedState = { status: "RETRIEVING" };

const feedStateReducer: Reducer<FeedState, FeedStateActions> = (
  state,
  action
) => {
  switch (action.type) {
    case "UPDATE_FEED":
      return { status: "RETRIEVED", feed: action.payload };

    default:
      return { status: "UNAVAILABLE" };
  }
};

export const useFeed = () => {
  const [feedState, dispatchFeedState] = useReducer<
    Reducer<FeedState, FeedStateActions>
  >(feedStateReducer, initialFeedState);

  return { dispatchFeedState, feedState };
};

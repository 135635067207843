import { Routes, Route, Navigate } from "react-router";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import { Feed } from "../pages/Feed";
import { Login, Register } from "../pages/Login";
import { ExtensionAuth } from "../pages/ExtensionAuth";
import { Profile } from "../pages/Profile";
import { useRouter } from "./useRouter";
import { BetaPage } from "../pages/BetaPage";

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Router = () => {
  const { signInStatus } = useRouter();

  if (signInStatus === "LOADING") {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  if (signInStatus === "NOT_BETA") {
    return <BetaPage></BetaPage>;
  }

  if (signInStatus === "API_FAILURE") {
    return (
      <PageContainer>
        We couldn't reach the Along service, please try to connect again later.
      </PageContainer>
    );
  }

  if (signInStatus === "SIGNED_IN") {
    return (
      <Routes>
        <Route path="/feed" element={<Feed />} />
        <Route path="/extension-auth" element={<ExtensionAuth />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Navigate to={"/feed"} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/extension-auth" element={<ExtensionAuth />} />
      <Route path="*" element={<Navigate to={"/login"} />} />
    </Routes>
  );
};

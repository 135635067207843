import { FlashOn, LabelImportant } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const Container = styled.div<{ isToggled?: boolean }>`
  width: 145px;
  height: 44px;
  background-color: #78788055;
  border-radius: 22px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isToggled ? "flex-end" : "flex-start")};
  align-items: center;
`;

const Knob = styled.div`
  height: 40px;
  width: 77px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  width: 145px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconsContainer = styled.div`
  justify-self: center;
  padding-right: 28px;
  padding-left: 28px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  style?: React.CSSProperties;
  isToggled?: boolean;
}

export const MainSlider = (props: Props) => (
  <Container style={props.style} isToggled={props.isToggled}>
    <Knob />
    <AbsoluteContainer>
      <IconsContainer>
        <LabelImportant fontSize="medium" color="inherit" />
        <FlashOn fontSize="medium" color="inherit" />
      </IconsContainer>
    </AbsoluteContainer>
  </Container>
);

import { NightsStay, WbSunny } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const Container = styled.div<{ isToggled?: boolean }>`
  width: 50px;
  height: 30px;
  background-color: #78788055;
  border-radius: 15px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isToggled ? "flex-end" : "flex-start")};
  align-items: center;
`;

const Knob = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 13px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SunnyIcon = styled(WbSunny)`
  color: "2e3a59";
  font-size: 16px;
`;

interface Props {
  style?: React.CSSProperties;
  isToggled?: boolean;
}

export const Slider = (props: Props) => (
  <Container style={props.style} isToggled={props.isToggled}>
    <Knob>
      {props.isToggled ? (
        <NightsStay fontSize="inherit" color="inherit" />
      ) : (
        <SunnyIcon fontSize="inherit" color="inherit" />
      )}
    </Knob>
  </Container>
);

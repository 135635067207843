import { User, UserCredential } from "@firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useSigninCheck } from "reactfire";
import { antipolarAPI } from "../../lib/api";

const requestMintTokenForAuthenticatedUser = (
  user: User,
  event: MessageEvent
) => {
  user.getIdToken().then((token) => {
    antipolarAPI(
      "/mint_token?" +
        new URLSearchParams({
          user_id: user.uid,
          user_token: token,
        })
    )
      .get()
      .text()
      .then((response) => {
        event.source?.postMessage(
          { messageType: "mint_token_payload", mintToken: response },
          // @ts-ignore
          event.origin
        );
      });
  });
};

export const useExtensionAuth = () => {
  const { data: signInCheckResult } = useSigninCheck();

  const [shouldNestLoginPage, setShouldNestLoginPage] =
    useState<boolean>(false);
  const [shouldNestRegisterPage, setShouldNestRegisterPage] =
    useState<boolean>(false);

  const mintTokenRequestEvent = useRef<MessageEvent>();

  const processMintTokenMessage = () => {
    const postMessage = (event: MessageEvent) => {
      if (event.data === "mint_token_request") {
        // Tell the extension we correctly received the message and are now preparing to send the response
        event.source?.postMessage(
          { messageType: "mint_token_request_received" },
          // @ts-ignore
          event.origin
        );
        if (signInCheckResult?.signedIn) {
          requestMintTokenForAuthenticatedUser(signInCheckResult.user, event);
        } else {
          mintTokenRequestEvent.current = event;
        }
      }
    };

    if (!!signInCheckResult && !signInCheckResult.signedIn) {
      setShouldNestLoginPage(true);
    }

    window.addEventListener("message", postMessage, false);

    return () => window.removeEventListener("message", postMessage, false);
  };

  const onLoginSuccess = (user: UserCredential) => {
    if (mintTokenRequestEvent.current) {
      requestMintTokenForAuthenticatedUser(
        user.user,
        mintTokenRequestEvent.current
      );
    }
  };

  const onAskRegister = () => {
    setShouldNestLoginPage(false);
    setShouldNestRegisterPage(true);
  };

  useEffect(processMintTokenMessage, [signInCheckResult]);

  return {
    shouldNestLoginPage,
    shouldNestRegisterPage,
    onLoginSuccess,
    onAskRegister,
  };
};

import { antipolarAPI } from "../../lib/api";
import { APIFeedArticle } from "./interfaces";

export const getFeedAnnotations = (
  user_id: string,
  user_token: string
): Promise<{ feed: APIFeedArticle[] }> =>
  antipolarAPI("/feed?" + new URLSearchParams({ user_id, user_token }))
    .get()
    .json();

export const getProfileFeedAnnotations = (
  target_user_id: string,
  user_id: string,
  user_token: string
): Promise<{ feed: APIFeedArticle[] }> =>
  antipolarAPI(
    "/profile_feed?" +
      new URLSearchParams({ target_user_id, user_id, user_token })
  )
    .get()
    .json();

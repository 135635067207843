import styled from "styled-components";
import { theme } from "../../../lib/theme";

export const Button = styled.button<{ takeFullSpace?: boolean }>`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.takeFullSpace
      ? `
          width: 100%;
        `
      : ""}
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 0px;
  padding: 15px;
  background: transparent;
  cursor: pointer;
`;

export const PrimaryButton = styled(Button)<{
  takeFullSpace?: boolean;
  marginBottom?: number;
}>`
  background-color: ${theme.colors.newPurple};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 15)}px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  ${(props) =>
    props.takeFullSpace
      ? `
          flex: 1;
        `
      : ""}
`;

export const SecondaryButton = styled(Button)`
  background-color: ${theme.colors.white};
  border: 1px solid #c4c4c4;
  color: grey;
  font-weight: 600;
  font-size: 12px;
  flex: 1;
`;

export const ButtonText = styled.span`
  font-family: "Work Sans";
  font-size: 14px;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const BoldLightButtonText = styled(ButtonText)`
  color: white;
  font-weight: 600;
  font-size: 12px;
  flex: 1;
`;

export const Divider = styled.div`
  background-color: #dfdfdf;
  height: 1px;
  margin: 10px;
`;

export const ErrorMessageText = styled.span`
  font-family: Work Sans;
  font-size: 14px;
  color: #ff1e00;
`;

export const SubText = styled.span`
  font-family: "Work Sans";
  margin-bottom: 10px;
`;

export const HeadText = styled.span`
  font-family: "Work Sans";
  font-weight: 600;
  margin-bottom: 35px;
`;

export const RegisterText = styled.span`
  font-family: "Work Sans";
  text-align: center;
`;

export const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.form`
  width: 450px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 5px 5px #00000045;
  border-radius: 20px;
  background-color: white;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex: 1 0 100%;
`;

export const HeaderTitle = styled.span`
  font-family: "Work Sans";
  font-weight: 600;
  font-size: 18px;
`;

import styled from "styled-components";
import { Logout } from "@mui/icons-material";
import { Button } from "@mui/material";

import { Slider } from "./components/Slider";
import alongLetterLogo from "../../assets/along-al-white.png";
import { useTopBar } from "./useTopBar";

import featureFlags from "../../settings/featureFlags.json";

const ProfilePicture = styled.img`
  height: 30px;
  width: 42px;
  object-fit: cover;
  border-radius: 15px;
  margin-right: 30px;
`;

const ProfilePictureOverlay = styled.div`
  position: absolute;
  height: 30px;
  width: 42px;
  border-radius: 15px;
  margin-right: 30px;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const TopBarContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: 50px;
  background-color: white;
  z-index: 10;
  box-shadow: 0px 2px 5px #0a090940;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  overflow: visible;
`;

const TopBarHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AlongLogoContainer = styled.div`
  margin-top: 40px;
  width: 68px;
  height: 68px;
  background-color: black;
  border-radius: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px #000000bb;
`;

const AlongLogoOverlay = styled(AlongLogoContainer)`
  position: absolute;
  margin-top: 0px;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const AlongLogoImage = styled.img`
  width: 35px;
  height: 35px;
  padding-right: 5px;
`;

const LogoutButtonText = styled.span`
  font-family: "Work Sans";
  margin-right: 5px;
`;

export const TopBar = () => {
  const { photoURL, goToProfile, goToFeed, handleLogout } = useTopBar();

  return (
    <>
      <TopBarContainer>
        <TopBarHorizontalContainer style={{ flex: 1 }}>
          <ProfilePicture src={photoURL} />
          <ProfilePictureOverlay onClick={goToProfile} />
        </TopBarHorizontalContainer>
        <AlongLogoContainer>
          <AlongLogoImage src={alongLetterLogo} />
          <AlongLogoOverlay onClick={goToFeed} />
        </AlongLogoContainer>
        <TopBarHorizontalContainer
          style={{ flex: 1, justifyContent: "flex-end" }}
        >
          {featureFlags.nightMode && <Slider style={{ marginRight: 30 }} />}
          <Button size="small" onClick={handleLogout}>
            <LogoutButtonText>Logout</LogoutButtonText>
            <Logout style={{ fontSize: 25 }} fontSize="inherit" />
          </Button>
        </TopBarHorizontalContainer>
      </TopBarContainer>
    </>
  );
};

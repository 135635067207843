import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LinesEllipsis from "react-lines-ellipsis";

import { Annotation } from "../../../modules/annotations/interfaces";
import featureFlags from "../../../settings/featureFlags.json";

const PROFILE_NAME_SECTION_WIDTH = 110;
const PROFILE_PICTURE_SIZE = 30;

const HorizontalContainer = styled.div`
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
`;

const CommentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CommentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Label = styled.span<{ color: string }>`
  border-radius: 10px;
  background-color: ${(props) => props.color};
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 11px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
`;

const CommentHeaderText = styled.span`
  font-family: Work Sans;
  font-size: 13px;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 600;
  flex: 1;
`;

const DateText = styled.span`
  font-family: Work Sans;
  font-size: 10px;
  font-weight: 300;
`;

const CommentText = styled.span`
  font-family: Work Sans;
  font-size: 11px;
  font-weight: 400;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
`;

const ProfileSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const ProfilePicture = styled.img`
  width: ${PROFILE_PICTURE_SIZE}px;
  height: ${PROFILE_PICTURE_SIZE}px;
  border-radius: ${PROFILE_PICTURE_SIZE / 2}px;
`;

const ProfilePictureOverlay = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const ProfileName = styled.span`
  font-family: Work Sans;
  font-size: 13px;
  font-weight: 500;
  width: ${PROFILE_NAME_SECTION_WIDTH}px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const formatDate = (date: Date) => {
  const now = new Date();
  const nowMs = now.getTime();
  const dateMs = date.getTime();
  const diffMinutes = (nowMs - dateMs) / 1000 / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;

  if (diffMinutes < 60) {
    return `${diffMinutes.toFixed(0)}m ago`;
  } else if (diffHours < 24) {
    return `${diffHours.toFixed(0)}h ago`;
  } else if (diffDays < 7) {
    return `${diffDays.toFixed(0)} day${diffDays > 1.5 ? "s" : ""} ago`;
  } else {
    return date.toDateString();
  }
};

interface Props {
  comment: Annotation;
  articleUrl: string;
}

export const AnnotationSubCard = ({ comment, articleUrl }: Props) => {
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/profile?uid=${comment.authorId}`);
  };

  return (
    <HorizontalContainer>
      <ProfileContainer>
        <ProfileSubContainer>
          <ProfilePicture src={comment.authorPicture} />
          <ProfilePictureOverlay onClick={goToProfile} />
        </ProfileSubContainer>
        <ProfileSubContainer>
          <ProfileName>{comment.authorName}</ProfileName>
          <DateText>{` • ${formatDate(comment.time)}`}</DateText>
        </ProfileSubContainer>
      </ProfileContainer>
      <CommentContainer>
        <CommentHeader>
          <LinesEllipsis
            text={`“${comment.quote}”`}
            ellipsis=" [...]”"
            component={CommentHeaderText}
            maxLine={3}
            style={{ cursor: "pointer" }}
            onClick={() => window.open(articleUrl)}
          />
          {featureFlags.labels && (
            <Label color={comment.labels[0].color}>
              {comment.labels[0].text}
            </Label>
          )}
        </CommentHeader>
        <CommentText>{comment.text}</CommentText>
      </CommentContainer>
    </HorizontalContainer>
  );
};

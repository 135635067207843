import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSigninCheck } from "reactfire";
import { getBetaAccessWithCode } from "../../modules/userData/api";

const initialValues = { betaAccessCode: "" };

export const useBetaPage = () => {
  const { data: signInCheckResult } = useSigninCheck();

  const [canUseAccessCode, setCanUseAccessCode] = useState<boolean>(true);
  const [errorResponse, setErrorResponse] = useState<string>("");

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setErrorResponse("");
      const user = signInCheckResult.user;
      if (!!user)
        user
          .getIdToken()
          .then((token) =>
            getBetaAccessWithCode(user.uid, token, values.betaAccessCode)
          )
          .then((response) => {
            if (response.success === "GRANTED") {
              document.location.reload();
            } else {
              setErrorResponse(
                "Beta access could not be granted, please check your key."
              );
            }
          })
          .catch(() => setErrorResponse("An undetermined error happened"));
    },
  });

  useEffect(() => {
    if (!signInCheckResult.user?.email) {
      return;
    }

    const userEmail = signInCheckResult.user.email;

    if (userEmail.includes("@stanford.edu")) {
      setCanUseAccessCode(true);
    }
  }, [signInCheckResult]);

  return { canUseAccessCode, formik, errorResponse };
};

import { SetStateAction } from "react";

import {
  ConsiderableLoginFirebaseErrors,
  ConsiderableRegisterFirebaseErrors,
  SigningStatus,
} from "./interfaces";
import {
  loginErrorMessageForFirebaseError,
  registerErrorMessageForFirebaseError,
} from "./constants";

export const handleSigningError =
  (
    signingType: "LOGIN" | "REGISTER",
    setSigningStatus: (value: SetStateAction<SigningStatus>) => void
  ) =>
  (error: any) => {
    const errorCode =
      signingType === "REGISTER"
        ? (error.code as keyof ConsiderableRegisterFirebaseErrors)
        : (error.code as keyof ConsiderableLoginFirebaseErrors);
    const errorMessages =
      signingType === "REGISTER"
        ? registerErrorMessageForFirebaseError
        : loginErrorMessageForFirebaseError;

    console.warn(error.code);
    setSigningStatus({
      type: "ERROR",
      error:
        errorCode in errorMessages
          ? //@ts-ignore
            errorMessages[errorCode]
          : "An unknown error has happened.",
    });
  };

import { Button, TextField } from "@mui/material";
import styled from "styled-components";
import { useBetaPage } from "./useBetaPage";

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 450px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px #b4b4b4;
  border-radius: 20px;
  padding: 15px;
`;

const BetaCodeSection = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubmitBetaCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const HeadText = styled.h4`
  font-family: "Work Sans";
  font-weight: 500;
  text-align: center;
`;

const ErrorText = styled.span`
  color: red;
  font-family: "Work Sans";
  font-weight: 500;
`;

export const BetaPage = () => {
  const { canUseAccessCode, formik, errorResponse } = useBetaPage();

  return (
    <PageContainer>
      <Container>
        {canUseAccessCode ? (
          <BetaCodeSection onSubmit={formik.handleSubmit}>
            <HeadText>You are currently not registered in the beta.</HeadText>
            <HeadText>Do you have an access code? Use it below</HeadText>
            <SubmitBetaCodeContainer>
              <TextField
                size="small"
                id="betaAccessCode"
                value={formik.values.betaAccessCode}
                onChange={formik.handleChange}
                style={{ marginRight: 10 }}
              />
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </SubmitBetaCodeContainer>
            <ErrorText>{errorResponse}</ErrorText>
          </BetaCodeSection>
        ) : (
          <HeadText>
            You are currently not registered in the beta. Please ask an
            administrator to register you.
          </HeadText>
        )}
      </Container>
    </PageContainer>
  );
};

import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

import { AuthProvider, StorageProvider, useFirebaseApp } from "reactfire";

import { Router } from "./navigation/Router";

const App = () => {
  useFirebaseApp();

  return (
    <AuthProvider sdk={getAuth()}>
      <StorageProvider sdk={getStorage()}>
        <Router />
      </StorageProvider>
    </AuthProvider>
  );
};

export default App;

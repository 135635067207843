import { Check, Edit } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import styled from "styled-components";
import { useModifiableTextField } from "./useModifiableTextField";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

interface Props {
  canModify?: boolean;
  fieldText: string;
  onUpdateFieldText: (text: string) => void;
  style?: React.CSSProperties;
}

export const ModifiableTextField = (props: Props) => {
  const {
    isModifying,
    updateModification,
    handleEnter,
    finishModifying,
    startModifying,
  } = useModifiableTextField(props.onUpdateFieldText);

  return (
    <Container>
      {isModifying ? (
        <>
          <TextField
            size="small"
            defaultValue={props.fieldText}
            onChange={updateModification}
            onKeyPress={handleEnter}
          />
          {props.canModify && (
            <IconButton size="small" onClick={finishModifying}>
              <Check fontSize="small" />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <span style={props.style}>{props.fieldText}</span>
          {props.canModify && (
            <IconButton size="small" onClick={startModifying}>
              <Edit fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Container>
  );
};

import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { useProfileSidebar } from "./useProfileSidebar";
import { ModifiableTextField } from "../../../../components/ModifiableTextField";

const PROFILE_PICTURE_SIZE = 130;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
`;

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const MaskedFileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 0px;
  height: 0px;
`;

const ProfilePicture = styled.img`
  width: ${PROFILE_PICTURE_SIZE}px;
  height: ${PROFILE_PICTURE_SIZE}px;
  object-fit: cover;
  border-radius: ${PROFILE_PICTURE_SIZE / 2}px;
  box-shadow: 0px 4px 10px #00000044;
`;

const ProfilePictureOverlay = styled.div`
  position: absolute;
  width: ${PROFILE_PICTURE_SIZE}px;
  height: ${PROFILE_PICTURE_SIZE}px;
  border-radius: ${PROFILE_PICTURE_SIZE / 2}px;
  cursor: pointer;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const nameFieldTextStyle: React.CSSProperties = {
  fontSize: "1.5em",
  fontFamily: "Work Sans",
  marginRight: "10px",
  fontWeight: 500,
};

const bioFieldTextStyle: React.CSSProperties = {
  fontSize: 12,
  fontFamily: "Work Sans",
  marginRight: "10px",
  fontWeight: 400,
};

export const ProfileSideBar = () => {
  const {
    isUserDataLoading,
    isSelfProfilePage,
    profilePicture,
    displayName,
    updateDisplayName,
    userBio,
    updateUserBio,
    requestUploadNewPicture,
    fileInputRef,
  } = useProfileSidebar();

  if (isUserDataLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <ProfilePictureContainer>
        <ProfilePicture src={profilePicture} />
        <MaskedFileInput
          type="file"
          accept="image/jpeg, image/png"
          ref={fileInputRef}
          onChange={requestUploadNewPicture}
        />
        {isSelfProfilePage && (
          <ProfilePictureOverlay onClick={() => fileInputRef.current.click()} />
        )}
      </ProfilePictureContainer>
      <ModifiableTextField
        fieldText={displayName}
        canModify={isSelfProfilePage}
        onUpdateFieldText={updateDisplayName}
        style={nameFieldTextStyle}
      />
      <ModifiableTextField
        fieldText={userBio}
        canModify={isSelfProfilePage}
        onUpdateFieldText={updateUserBio}
        style={bioFieldTextStyle}
      />
    </Container>
  );
};

import {
  APIFeedAnnotation,
  APIFeedArticle,
  FeedArticleElement,
} from "./interfaces";

const formatUrl = (url: string) =>
  url.split("//")[1].split("/")[0].replace("www.", "");

export const adaptAPIFeed = (
  feedElements: APIFeedArticle[]
): FeedArticleElement[] =>
  feedElements.map((apiArticleElement: APIFeedArticle) => ({
    name: apiArticleElement.name,
    url: apiArticleElement.url,
    siteUrl: formatUrl(apiArticleElement.url),
    favicon: apiArticleElement.favicon,
    annotations: apiArticleElement.annotations.map(
      (apiAnnotation: APIFeedAnnotation) => ({
        quote: apiAnnotation.textInHtml,
        text: apiAnnotation.comment,
        time: new Date(apiAnnotation.date),
        authorId: apiAnnotation.author_id,
        authorName: apiAnnotation.author_name,
        authorPicture: apiAnnotation.author_picture,
        labels: [{ text: "The Ocean Cleanup", color: "#09cbe1" }],
      })
    ),
  }));

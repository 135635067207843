import { Button, CircularProgress } from "@mui/material";
import styled from "styled-components";

import { TopBar } from "../../components/TopBar";
import { FeedArticleElement } from "../../modules/annotations/interfaces";
import { AnnotatedArticleCard } from "./components/AnnotatedArticleCard";
import { MainSlider } from "./components/MainSlider";
import { useMainFeed } from "./useFeed";

import featureFlags from "../../settings/featureFlags.json";
import ChromeWebStore from "../../assets/ChromeWebStore.svg";

const FEED_WIDTH = 700;

const FeedTitle = styled.h1`
  font-family: Work Sans;
  font-size: 26px;
  font-weight: 600;
  margin-top: 0px;
`;

const PageContainer = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeedHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const ExtensionDownloadSection = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #0a090940;
  padding: 5px;
  padding-left: 15px;
`;

const ExtensionDownloadText = styled.span`
  font-family: "Work Sans";
  text-transform: none;
  color: black;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  margin-right: 10px;
`;

const AnnotationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${FEED_WIDTH}px;
`;

export const Feed = () => {
  const { feedState, goToChromeWebStore } = useMainFeed();

  return (
    <div>
      <TopBar />
      <PageContainer>
        <FeedHeader>
          {featureFlags.differentFeeds && (
            <MainSlider style={{ marginBottom: "30px" }} />
          )}
          <FeedTitle>{"Feed"}</FeedTitle>
        </FeedHeader>
        <ExtensionDownloadSection
          onClick={goToChromeWebStore}
          style={{ marginBottom: 10 }}
        >
          <ExtensionDownloadText>
            Is this your first using Along ?<br />
            Download the extension to start annotating !
          </ExtensionDownloadText>
          <Button size="small" style={{ marginRight: 10 }}>
            <img
              src={ChromeWebStore}
              height={40}
              alt="Along Web Extension on Chrome Web Store"
            />
          </Button>
        </ExtensionDownloadSection>
        {feedState.status === "RETRIEVED" ? (
          <AnnotationsContainer>
            {feedState.feed.map((feedElement: FeedArticleElement) => (
              <AnnotatedArticleCard
                feedElement={feedElement}
                key={feedElement.url}
              />
            ))}
          </AnnotationsContainer>
        ) : (
          <CircularProgress />
        )}
      </PageContainer>
    </div>
  );
};

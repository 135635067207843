import { FunctionComponent } from "react";
import styled from "styled-components";

const AlongLogo = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 20px;
  left: 20px;
`;

const AlongLogoImage = styled.img`
  width: 60px;
  margin-right: 10px;
`;

const AlongLogoText = styled.span`
  font-family: "Work Sans";
  font-weight: 600;
  font-size: 20px;
`;

const PageBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("https://static.wixstatic.com/media/5f6057_28ae157eaacf49f1929d47d9447c493d~mv2.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {}

export const PageContainer: FunctionComponent<Props> = (props) => (
  <PageBackground>
    <>
      <AlongLogo>
        <AlongLogoImage src="https://static.wixstatic.com/media/5f6057_e998fcb54f0c487693e198ba92c7ec76~mv2.png" />
        <AlongLogoText>along</AlongLogoText>
      </AlongLogo>
      {props.children}
    </>
  </PageBackground>
);

const gridUnit = 5;

const shadows = {
  medium: `
    shadow-color: #34485b;
    shadow-offset: 0px 1px;
    shadow-opacity: 0.3;
    shadow-radius: 3px;
    elevation: 5;
    `,
};

export const theme = {
  /**
   * 5
   */
  gridUnit,
  colors: {
    primary: "#4860d2",
    secondary: "#ac6363",
    blue: "#4860d2",
    lightBlue: "#7080d1",
    veryLightBlue: "#e0eaf9",
    lightRed: "#e9a2ad",
    darkRed: "#d3455b",
    mint: "#8ed7cf",
    darkMint: "#1cae9f",
    orange: "#e8833a",
    purple: "#b43afe",
    darkPurple: "#A578AC",
    newPurple: "#6800EC",
    lightDarkPurple: "#BB9BC0",
    white: "#ffffff",
    black: "#000000",
    paleRose: "#BFAFC924",
  },
  fontSizes: {
    tiny: 10,
    small: 12,
    medium: 14,
    large: 16,
    big: 18,
  },
  shadows,
};

export type Theme = typeof theme;

import { ref, uploadBytes, UploadResult } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStorage, useUser } from "reactfire";
import {
  getUserData,
  postUpdateUserData,
} from "../../../../modules/userData/api";

const makeImageURLFromUploadResult = (result: UploadResult) =>
  `https://firebasestorage.googleapis.com/v0/b/${
    result.ref.bucket
  }/o/${result.ref.fullPath.replaceAll("/", "%2F")}?alt=media`;

export const useProfileSidebar = () => {
  const location = useLocation();

  const { data: user } = useUser();

  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(true);
  const [isSelfProfilePage, setIsSelfProfilePage] = useState<boolean>(false);
  const [userBio, setUserBio] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");

  const updateUserData = () => {
    if (!!user?.uid) {
      const user_id = new URLSearchParams(location.search).get("uid");

      setIsSelfProfilePage(user_id === null || user_id === user.uid);

      const profile_user_id = !!user_id ? user_id : user.uid;

      getUserData(profile_user_id).then((res) => {
        setUserBio(res.bio);
        setProfilePicture(res.photo_url);
        setDisplayName(res.display_name);
        setIsUserDataLoading(false);
      });
    }
  };

  useEffect(updateUserData, [user, location]);

  const updateUserBio = (text: string) => {
    if (!!user?.uid) {
      user
        .getIdToken()
        .then((token) =>
          postUpdateUserData(user.uid, token, {
            bio: text,
            displayName: displayName,
          })
        )
        .then(updateUserData);
    }
  };

  const updateDisplayName = (text: string) => {
    if (!!user?.uid) {
      user
        .getIdToken()
        .then((token) =>
          postUpdateUserData(user.uid, token, {
            bio: userBio,
            displayName: text,
          })
        )
        .then(updateUserData);
    }
  };

  const storage = useStorage();
  const fileInputRef = useRef<any>();

  const requestUploadNewPicture = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!user?.uid) return;
    const file = fileInputRef.current.files[0];
    const profilePictureRef = ref(
      storage,
      `profile_pictures/${user.uid}.${(file.name as string).split(".").pop()}`
    );

    setIsUserDataLoading(true);

    uploadBytes(profilePictureRef, file)
      .then(makeImageURLFromUploadResult)
      .then(async (imageUrl: string) => ({
        token: await user.getIdToken(),
        imageUrl,
      }))
      .then(({ token, imageUrl }) =>
        postUpdateUserData(user.uid, token, {
          bio: userBio,
          displayName: displayName,
          photoUrl: imageUrl,
        })
      )
      .then(updateUserData)
      .finally(() => setIsUserDataLoading(false));
  };

  return {
    isUserDataLoading,
    isSelfProfilePage,
    profilePicture,
    displayName,
    updateDisplayName,
    userBio,
    updateUserBio,
    requestUploadNewPicture,
    fileInputRef,
  };
};

import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  UserCredential,
} from "@firebase/auth";
import { useFormik } from "formik";
import { initialRegisterValues } from "./constants";
import { handleSigningError } from "./errors";
import { useCommonSigning } from "./useCommonSigning";

export const useRegister = (
  onLoginSuccess?: (userC: UserCredential) => void
) => {
  const {
    toggleShowPassword,
    showPassword,
    handleGoogleLogin,
    correctOnLoginSuccess,
    signingStatus,
    setSigningStatus,
  } = useCommonSigning(onLoginSuccess);

  const formik = useFormik({
    initialValues: initialRegisterValues,
    onSubmit: (values) => {
      const auth = getAuth();
      setSigningStatus({ type: "SIGNING" });
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredentials) => {
          updateProfile(userCredentials.user, {
            displayName: values.displayName,
          });
          return userCredentials;
        })
        .then(correctOnLoginSuccess)
        .catch(handleSigningError("REGISTER", setSigningStatus));
    },
  });

  return {
    signingStatus,
    formik,
    showPassword,
    toggleShowPassword,
    handleGoogleLogin,
  };
};

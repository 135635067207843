import { antipolarAPI } from "../../lib/api";
import {
  UserDetailsAPI,
  UserBetaStatusAPI,
  BetaCodeRequestResultAPI,
} from "./interfaces";

export const getUserData = (userId: string): Promise<UserDetailsAPI> =>
  antipolarAPI("/user_data?" + new URLSearchParams({ user_id: userId }))
    .get()
    .json();

export const postUpdateUserData = (
  userId: string,
  userToken: string,
  userData: { bio: string; displayName: string; photoUrl?: string }
) =>
  antipolarAPI("/update_user_data?")
    .post({
      user_id: userId,
      user_token: userToken,
      user_bio: userData.bio,
      user_display_name: userData.displayName,
      user_profile_picture: userData.photoUrl,
    })
    .json();

export const getUserBetaStatus = (userId: string): Promise<UserBetaStatusAPI> =>
  antipolarAPI("/beta_user_status?" + new URLSearchParams({ user_id: userId }))
    .get()
    .json();

export const getBetaAccessWithCode = (
  userId: string,
  userToken: string,
  betaCode: string
): Promise<BetaCodeRequestResultAPI> =>
  antipolarAPI(
    "/use_beta_code?" +
      new URLSearchParams({
        user_id: userId,
        user_token: userToken,
        beta_code: betaCode,
      })
  )
    .get()
    .json();

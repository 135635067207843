import { CircularProgress } from "@mui/material";
import { UserCredential } from "@firebase/auth";

import { useRegister } from "./hooks/useRegister";
import {
  DisplayNameField,
  EmailField,
  PasswordField,
} from "./components/Fields";
import googleGLogo from "../../assets/google-g-logo.svg";
import featureFlags from "../../settings/featureFlags.json";
import {
  BoldLightButtonText,
  ErrorMessageText,
  PrimaryButton,
  FieldSection,
  Container,
  Header,
  HeaderTitle,
  SecondaryButton,
} from "./components/LayoutComponents";
import { PageContainer } from "./components/PageContainer";

interface Props {
  onLoginSuccess?: (userC: UserCredential) => void;
}

export const Register = (props: Props) => {
  const {
    signingStatus,
    formik,
    showPassword,
    toggleShowPassword,
    handleGoogleLogin,
  } = useRegister(props.onLoginSuccess);

  return (
    <PageContainer>
      <Container onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderTitle>Sign Up</HeaderTitle>
        </Header>
        <FieldSection>
          <EmailField
            value={formik.values.email}
            handleChange={formik.handleChange}
          />
          <DisplayNameField
            value={formik.values.displayName}
            handleChange={formik.handleChange}
          />
          <PasswordField
            value={formik.values.password}
            handleChange={formik.handleChange}
            showPassword={showPassword}
            toggleShowPassword={toggleShowPassword}
          />
          {signingStatus.type === "ERROR" && (
            <ErrorMessageText>{signingStatus.error}</ErrorMessageText>
          )}
          {signingStatus.type === "SIGNING" ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <PrimaryButton takeFullSpace type="submit">
                <BoldLightButtonText>Sign up</BoldLightButtonText>
              </PrimaryButton>
              {featureFlags.googleLogin && (
                <SecondaryButton takeFullSpace onClick={handleGoogleLogin}>
                  <img
                    src={googleGLogo}
                    style={{ marginRight: 10 }}
                    height={18}
                    alt="Google G Logo"
                  />
                  Sign up with Google
                </SecondaryButton>
              )}
            </>
          )}
        </FieldSection>
      </Container>
    </PageContainer>
  );
};

import { useNavigate } from "react-router";
import { useUser } from "reactfire";
import { signOut, getAuth } from "@firebase/auth";

export const useTopBar = () => {
  const { data: user } = useUser();

  const navigate = useNavigate();

  const goToProfile = () => navigate("/profile");
  const goToFeed = () => navigate("/feed");

  const photoURL = !!user?.photoURL ? user.photoURL : "";

  const handleLogout = () => {
    signOut(getAuth());
  };

  return {
    photoURL,
    goToProfile,
    goToFeed,
    handleLogout,
  };
};

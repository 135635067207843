import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import { TopBar } from "../../components/TopBar";
import { theme } from "../../lib/theme";
import { FeedArticleElement } from "../../modules/annotations/interfaces";
import { AnnotatedArticleCard } from "../Feed/components/AnnotatedArticleCard";
import { useProfile } from "./useProfile";
import { ProfileSideBar } from "./components/ProfileSideBar/ProfileSideBar";
import featureFlags from "../../settings/featureFlags.json";

const PROFILE_FEED_WIDTH = 650;

const PageContainer = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SidebarContainer = styled.div`
  margin-right: 100px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  background-color: ${(props) => theme.colors.paleRose};
  border-radius: 10px;
  margin-bottom: 40px;
`;

const HeaderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NumberText = styled.span`
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 30px;
`;

const NumberSubText = styled(NumberText)`
  font-size: 12px;
`;

const AnnotationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${PROFILE_FEED_WIDTH}px;
`;

export const Profile = () => {
  const { feedState } = useProfile();

  return (
    <div>
      <TopBar />
      <PageContainer>
        <SidebarContainer>
          <ProfileSideBar />
        </SidebarContainer>
        <MainContainer>
          {featureFlags.profileStats && (
            <HeaderContainer>
              <HeaderItemContainer>
                <NumberText>98</NumberText>
                <NumberSubText>FOLLOWERS</NumberSubText>
              </HeaderItemContainer>
              <HeaderItemContainer>
                <NumberText>1</NumberText>
                <NumberSubText>FOLLOWING</NumberSubText>
              </HeaderItemContainer>
              {feedState.status === "RETRIEVED" ? (
                <HeaderItemContainer>
                  <NumberText>{feedState.feed.length}</NumberText>
                  <NumberSubText>ANNOTATIONS</NumberSubText>
                </HeaderItemContainer>
              ): (
                <CircularProgress />
              )}
            </HeaderContainer>
          )}
          {feedState.status === "RETRIEVED" ? (
            <AnnotationsContainer>
              {feedState.feed.map((feedElement: FeedArticleElement) => (
                <AnnotatedArticleCard
                  key={feedElement.url} // Key should be the id of the annotation, this could create bugs later
                  feedElement={feedElement}
                />
              ))}
            </AnnotationsContainer>
          ) : (
            <CircularProgress />
          )}
        </MainContainer>
      </PageContainer>
    </div>
  );
};

import { useState } from "react";

export const useModifiableTextField = (
  onUpdateFieldText: (text: string) => void
) => {
  const [isModifying, setIsModifying] = useState<boolean>(false);
  const [modification, setModification] = useState<string>("");

  const startModifying = () => {
    setIsModifying(true);
  };

  const finishModifying = async () => {
    setIsModifying(false);
    onUpdateFieldText(modification);
  };

  const updateModification = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setModification(event.target.value);
  };

  const handleEnter: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      finishModifying();
    }
  };

  return {
    isModifying,
    updateModification,
    handleEnter,
    finishModifying,
    startModifying,
  };
};

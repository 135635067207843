import { useState } from "react";
import { useNavigate } from "react-router";
import {
  signInWithPopup,
  getAuth,
  GoogleAuthProvider,
  UserCredential,
} from "@firebase/auth";
import { SigningStatus } from "./interfaces";

export const useCommonSigning = (
  onLoginSuccess?: (userC: UserCredential) => void,
  onAskRegister?: () => void
) => {
  const navigate = useNavigate();

  const [signingStatus, setSigningStatus] = useState<SigningStatus>({
    type: "NOT_SIGNED",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => setShowPassword((state) => !state);

  const handleGoogleLogin = () => {
    signInWithPopup(getAuth(), new GoogleAuthProvider()).then(
      !!onLoginSuccess ? onLoginSuccess : () => navigate("/profile")
    );
  };

  const goToRegister = () =>
    !!onAskRegister ? onAskRegister() : navigate("/register");

  const correctOnLoginSuccess = !!onLoginSuccess
    ? onLoginSuccess
    : () => navigate("/profile");

  return {
    toggleShowPassword,
    showPassword,
    goToRegister,
    handleGoogleLogin,
    correctOnLoginSuccess,
    signingStatus,
    setSigningStatus,
  };
};

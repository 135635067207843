import { UserCredential } from "@firebase/auth";
import { CircularProgress } from "@mui/material";

import { useLogin } from "./hooks/useLogin";
import googleGLogo from "../../assets/google-g-logo.svg";
import featureFlags from "../../settings/featureFlags.json";
import { EmailField, PasswordField } from "./components/Fields";
import {
  ErrorMessageText,
  PrimaryButton,
  SecondaryButton,
  FieldSection,
  Container,
  Header,
  HeaderTitle,
} from "./components/LayoutComponents";
import { PageContainer } from "./components/PageContainer";

interface Props {
  onLoginSuccess?: (userC: UserCredential) => void;
  onAskRegister?: () => void;
}

export const Login = (props: Props) => {
  const {
    handleGoogleLogin,
    signingStatus,
    formik,
    showPassword,
    toggleShowPassword,
    goToRegister,
  } = useLogin(props.onLoginSuccess, props.onAskRegister);

  return (
    <PageContainer>
      <Container onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderTitle>Sign In</HeaderTitle>
          <PrimaryButton onClick={goToRegister} marginBottom={1}>
            Register
          </PrimaryButton>
        </Header>
        <FieldSection>
          <EmailField
            value={formik.values.email}
            handleChange={formik.handleChange}
          />
          <PasswordField
            value={formik.values.password}
            handleChange={formik.handleChange}
            showPassword={showPassword}
            toggleShowPassword={toggleShowPassword}
          />
          {signingStatus.type === "ERROR" && (
            <ErrorMessageText>{signingStatus.error}</ErrorMessageText>
          )}
          {signingStatus.type === "SIGNING" ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <PrimaryButton takeFullSpace type="submit">
                Sign in
              </PrimaryButton>
              {featureFlags.googleLogin && (
                <SecondaryButton takeFullSpace onClick={handleGoogleLogin}>
                  <img
                    src={googleGLogo}
                    style={{ marginRight: 10 }}
                    height={18}
                    alt="Google G Logo"
                  />
                  Sign in with Google
                </SecondaryButton>
              )}
            </>
          )}
        </FieldSection>
      </Container>
    </PageContainer>
  );
};

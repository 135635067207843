import { useEffect, useState } from "react";
import { useSigninCheck } from "reactfire";
import { getUserBetaStatus } from "../modules/userData/api";

type SignInBetaStatus =
  | "LOADING"
  | "SIGNED_IN"
  | "NOT_BETA"
  | "SIGNED_OFF"
  | "API_FAILURE";

export const useRouter = () => {
  const { data: signInCheckResult } = useSigninCheck();

  const [signInStatus, setSignInStatus] = useState<SignInBetaStatus>("LOADING");

  useEffect(() => {
    if (!signInCheckResult) {
      return;
    }
    if (!signInCheckResult.signedIn) {
      setSignInStatus("SIGNED_OFF");
      return;
    }
    getUserBetaStatus(signInCheckResult.user.uid)
      .then((result) => {
        setSignInStatus(result.is_beta_user ? "SIGNED_IN" : "NOT_BETA");
      })
      .catch(() => setSignInStatus("API_FAILURE"));
  }, [signInCheckResult]);

  return { signInStatus };
};

import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const EmailField = (props: {
  value: string;
  handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
}) => (
  <TextField
    variant="outlined"
    size="small"
    fullWidth
    id="email"
    placeholder="E-mail"
    style={{ marginBottom: 10 }}
    value={props.value}
    onChange={props.handleChange}
  />
);

export const DisplayNameField = (props: {
  value: string;
  handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
}) => (
  <TextField
    variant="outlined"
    size="small"
    fullWidth
    id="displayName"
    placeholder="Display Name"
    style={{ marginBottom: 10 }}
    value={props.value}
    onChange={props.handleChange}
  />
);

export const PasswordField = (props: {
  value: string;
  handleChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  showPassword: boolean;
  toggleShowPassword: () => void;
}) => (
  <TextField
    variant="outlined"
    size="small"
    fullWidth
    type={props.showPassword ? "text" : "password"}
    placeholder="Password"
    style={{ marginBottom: 10 }}
    id="password"
    value={props.value}
    onChange={props.handleChange}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={props.toggleShowPassword}>
            {props.showPassword ? (
              <VisibilityOff fontSize="small" />
            ) : (
              <Visibility fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

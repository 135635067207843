import { useEffect } from "react";
import { useLocation } from "react-router";
import { useUser } from "reactfire";
import { adaptAPIFeed } from "../../modules/annotations/adapters";
import { getProfileFeedAnnotations } from "../../modules/annotations/api";
import { useFeed } from "../../modules/feed/hooks";

export const useProfile = () => {
  const { dispatchFeedState, feedState } = useFeed();

  const { data: user } = useUser();

  const location = useLocation();

  useEffect(() => {
    if (!!user?.uid) {
      const user_id = new URLSearchParams(location.search).get("uid");

      const targetUserId = !!user_id ? user_id : user.uid;

      user
        .getIdToken()
        .then((token) =>
          getProfileFeedAnnotations(targetUserId, user.uid, token)
        )
        .then((res) => {
          dispatchFeedState({
            type: "UPDATE_FEED",
            payload: adaptAPIFeed(res.feed),
          });
        });
    }
  }, [user, location, dispatchFeedState]);

  return {
    feedState,
  };
};

export const loginErrorMessageForFirebaseError = {
  "auth/invalid-email": "The e-mail address you entered is invalid",
  "auth/wrong-password": "The credentials you used are incorrect.",
  "auth/user-not-found": "The credentials you used are incorrect.",
  "auth/too-many-requests":
    "You have failed too many sign in attempts, please wait a bit before trying again",
};

export const registerErrorMessageForFirebaseError = {
  "auth/invalid-email": "The e-mail address you entered is invalid",
  "auth/weak-password": "The password you used is too weak.",
  "auth/email-already-in-use": "This e-mail address is already in-use",
  "auth/internal-error": "Unknown error. Did you fill all the fields?",
};

export const initialLoginValues = {
  email: "",
  password: "",
};

export const initialRegisterValues = {
  email: "",
  displayName: "",
  password: "",
};

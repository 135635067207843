import {
  getAuth,
  UserCredential,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import { useFormik } from "formik";
import { initialLoginValues } from "./constants";
import { handleSigningError } from "./errors";
import { useCommonSigning } from "./useCommonSigning";

export const useLogin = (
  onLoginSuccess?: (userC: UserCredential) => void,
  onAskRegister?: () => void
) => {
  const {
    toggleShowPassword,
    showPassword,
    goToRegister,
    handleGoogleLogin,
    correctOnLoginSuccess,
    signingStatus,
    setSigningStatus,
  } = useCommonSigning(onLoginSuccess, onAskRegister);

  const formik = useFormik({
    initialValues: initialLoginValues,
    onSubmit: (values) => {
      const auth = getAuth();
      setSigningStatus({ type: "SIGNING" });
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(correctOnLoginSuccess)
        .catch(handleSigningError("LOGIN", setSigningStatus));
    },
  });

  return {
    handleGoogleLogin,
    signingStatus,
    formik,
    showPassword,
    toggleShowPassword,
    goToRegister,
  };
};

import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { Login, Register } from "../Login";
import { useExtensionAuth } from "./useExtensionAuth";

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeadText = styled.span`
  font-family: "Work Sans";
  font-weight: 600;
  margin-bottom: 35px;
`;

export const ExtensionAuth = () => {
  const {
    shouldNestLoginPage,
    shouldNestRegisterPage,
    onLoginSuccess,
    onAskRegister,
  } = useExtensionAuth();

  if (shouldNestLoginPage) {
    return (
      <Login onLoginSuccess={onLoginSuccess} onAskRegister={onAskRegister} />
    );
  } else if (shouldNestRegisterPage) {
    return <Register onLoginSuccess={onLoginSuccess} />;
  } else {
    return (
      <PageContainer>
        <HeadText>We are logging you in!</HeadText>
        <CircularProgress />
      </PageContainer>
    );
  }
};

import { useEffect } from "react";
import { useUser } from "reactfire";
import { adaptAPIFeed } from "../../modules/annotations/adapters";
import { getFeedAnnotations } from "../../modules/annotations/api";
import { useFeed } from "../../modules/feed/hooks";

import links from "../../settings/links.json";

export const useMainFeed = () => {
  const { dispatchFeedState, feedState } = useFeed();

  const { data: user } = useUser();

  useEffect(() => {
    if (!!user?.uid) {
      user
        .getIdToken()
        .then((token) => getFeedAnnotations(user.uid, token))
        .then((res) => {
          dispatchFeedState({
            type: "UPDATE_FEED",
            payload: adaptAPIFeed(res.feed),
          });
        });
    }
  }, [user, dispatchFeedState]);

  const goToChromeWebStore = () => window.open(links.DOWNLOAD_EXTENSION_URL);

  return { feedState, goToChromeWebStore };
};

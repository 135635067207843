import { useState } from "react";
import { FeedArticleElement } from "../../../modules/annotations/interfaces";

const INVALID_FAVICONS = [
  "data:text/xml;base64,U2VlIC9jb3JzZGVtbyBmb3IgbW9yZSBpbmZvCg==",
  "",
];

const STEP_INCREASE_DISPLAYED_ANNOTATIONS = 5;

export const useAnnotatedArticleCard = (feedElement: FeedArticleElement) => {
  let favicon = feedElement.favicon;

  if (INVALID_FAVICONS.indexOf(favicon) !== -1) {
    favicon = `https://f1.allesedv.com/64/${feedElement.siteUrl}`;
  }

  const [maxDisplayedAnnotations, setMaxDisplayedAnnotations] =
    useState<number>(3);

  const increaseMaxDisplayedAnnotations = () =>
    setMaxDisplayedAnnotations(
      (state) => state + STEP_INCREASE_DISPLAYED_ANNOTATIONS
    );

  return { favicon, maxDisplayedAnnotations, increaseMaxDisplayedAnnotations };
};

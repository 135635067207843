import styled from "styled-components";
import { KeyboardArrowDown, MoreHoriz, Share } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import {
  Annotation,
  FeedArticleElement,
} from "../../../modules/annotations/interfaces";
import { useAnnotatedArticleCard } from "./useAnnotatedArticleCard";

import { AnnotationSubCard } from "./AnnotationSubCard";

const Container = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const SiteAndSettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const NameAndDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const SiteInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FaviconPicture = styled.img`
  margin-right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 25px;
`;

const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 450px;
`;

const NameText = styled.span`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
`;

const ArticleText = styled.a`
  font-family: Work Sans;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  color: black;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
`;

const NumberOfAnnotationsButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface Props {
  feedElement: FeedArticleElement;
}

export const AnnotatedArticleCard = ({ feedElement, ...props }: Props) => {
  const { favicon, maxDisplayedAnnotations, increaseMaxDisplayedAnnotations } =
    useAnnotatedArticleCard(feedElement);

  return (
    <Container>
      <HeaderContainer>
        <SiteAndSettingsContainer>
          <SiteInfoContainer>
            <FaviconPicture src={favicon} />
            <NameText>{feedElement.siteUrl}</NameText>
          </SiteInfoContainer>
          <SettingsContainer>
            <Share fontSize="small" />
            <MoreHoriz style={{ marginLeft: "10px" }} fontSize="small" />
          </SettingsContainer>
        </SiteAndSettingsContainer>
        <NameAndDetailsContainer>
          <DetailsContainer>
            <ArticleText href={feedElement.url}>{feedElement.name}</ArticleText>
          </DetailsContainer>
        </NameAndDetailsContainer>
      </HeaderContainer>
      {feedElement.annotations
        .slice(0, maxDisplayedAnnotations)
        .map((comment: Annotation) => (
          <AnnotationSubCard
            comment={comment}
            key={comment.time.getTime()}
            articleUrl={feedElement.url}
          />
        ))}
      {feedElement.annotations.length > maxDisplayedAnnotations && (
        <NumberOfAnnotationsButtonContainer>
          <IconButton size="small" onClick={increaseMaxDisplayedAnnotations}>
            <KeyboardArrowDown />
          </IconButton>
        </NumberOfAnnotationsButtonContainer>
      )}
    </Container>
  );
};
